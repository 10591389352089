<template>
  <div class="team">
    <Headbar title="我的团队"></Headbar>

    <div class="total">
      <div class="total-item">
        <img src="@/assets/img/user/t1.png" class="total-icon" />
        <div>
          <div class="total-label">{{ $t('社区人数') }}</div>
          <div class="total-value">{{ teamcount }}</div>
        </div>
      </div>
      <div class="total-item">
        <img src="@/assets/img/user/t2.png" class="total-icon" />
        <div>
          <div class="total-label">{{ $t('社区业绩') }}</div>
          <div class="total-value">{{ teamyeji }}</div>
        </div>
      </div>
      <!-- <div class="total-item q-mt-sm">
        <img src="@/assets/img/user/t2.png" class="total-icon" />
        <div>
          <div class="total-label">{{ $t('A区业绩') }}</div>
          <div class="total-value">{{ aqu }}</div>
        </div>
      </div>
      <div class="total-item q-mt-sm">
        <img src="@/assets/img/user/t2.png" class="total-icon" />
        <div>
          <div class="total-label">{{ $t('B区业绩') }}</div>
          <div class="total-value">{{ bqu }}</div>
        </div>
      </div> -->
    </div>

    <div style="text-align: center;color: #ffd585;font-weight: bold;font-size: 18px;margin-top: 20px;">{{ $t('我的直推') }}
    </div>
    <div class="list">
      <div class="list-label">
        <div class="row1">{{ $t('序号') }}</div>
        <div class="row2">{{ $t('地址') }}</div>
        <div class="row3">{{ $t('注册时间') }}</div>
      </div>
      <div v-for="(item, index) in list" :key="index">
        <div class="list-value">
          <div class="row1">{{ index + 1 }}</div>
          <div class="row2">{{ abbr(item.address, 4, 4) }}</div>
          <div class="row3">{{ item.rdt }}</div>
        </div>
      </div>
      <empty :text="$t('暂无记录')" v-if="list.length == 0" />
    </div>
  </div>
</template>

<script>
import CustomComponent from "@/components/CustomComponent";
import { Icon } from 'vant';
export default {
  name: '',
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      us: null,
      data: {
        list: []
      },
      list: [],
      teamyeji: 0,
      teamcount: 0,
      aqu: 0,
      bqu: 0
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getList()
      this.getUser()
    }
  },
  methods: {
    onClick(item) {
      item.more = item.more ? false : true
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    getUser() {
      let _this = this
      _this.$request.post(
        "api/Users/Get",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let us = res.data.data.us
          _this.teamyeji = us.teamyeji
          _this.teamcount = us.teamcount
        }
      )
    },
    getList() {
      let _this = this
      _this.$request.post(
        "api/Users/GetUserListByReid",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          reid: _this.us.id
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data.list
          _this.list = data
        }
      )
    }
  }
}
</script>

<style scoped>
.team {
  padding: 10px;
}

.total {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.total-item {
  width: 48%;
  display: flex;
  padding: 20px 15px;
  border-radius: 10px;
  background-color: #1b1b1b;
}

.total-icon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.total-label {
  font-size: 14px;
  color: #aaaaaa;
}

.total-value {
  color: #fff;
  font-size: 13px;
}

.list {
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px;
  border: 0.5px solid rgb(82, 74, 101);
}

.list-label {
  display: flex;
  font-size: 12px;
  color: #999999;
}

.list-value {
  padding: 6px 0;
  display: flex;
  color: #fff;
}


.level-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px 15px;
  color: #fff;
  background: rgb(45, 38, 56);
  border-radius: 10px;
  font-size: 15px;
}

.row1,
.row2,
.row3 {
  text-align: center;
}

.row1 {
  flex: 1;
}

.row2 {
  flex: 3;
}

.row3 {
  flex: 4;
}

.list-more {
  color: #fff;
  padding: 0 0 20px 10px;
}

.list-more-value {
  text-align: left;
}

.more-value {
  color: #e56efa;
  overflow-wrap: break-word;
}
</style>